import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Spinner,
  Badge,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Components/Common/Pagination";
import { Link } from "react-router-dom";
import moment from "moment";
import SearchInput from "../../Components/SearchInput";
import {
  deleteClient,
  getAllClients,
  reset,
} from "../../redux/clients/client.slices";
import { reset as resetLead } from "../../redux/leads/lead.slices";
import DeleteModal from "../../Components/Common/DeleteModal";
import EditLeadModal from "../../Components/leads/EditLeadModal";
import { getLead } from "../../redux/leads/lead.slices";
import { toast } from "react-toastify";

function Clients() {
  const dispatch = useDispatch();

  const [showEditLead, setShowEditLead] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const [showDelete, setshowDelete] = useState(false);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  const {
    isLoading,
    clients,
    message: messageClient,
    isSuccess,
  } = useSelector((state) => state.clients);

  const {
    lead,
    message,
    isSuccess: isSuccessLead,
    isLoading: isLoadingLead,
  } = useSelector((state) => state.leads);

  useEffect(() => {
    if (isSuccessLead) {
      toast.success("Client " + message);

      setshowDelete(false);
      setShowEditLead(false);
      dispatch(reset());
      dispatch(resetLead());
      dispatch(getAllClients());
    }
  }, [dispatch, isSuccessLead]);

  useEffect(() => {
    if (isSuccess) {
      setshowDelete(false);
      setShowEditLead(false);
      toast.success("Client " + messageClient);
      dispatch(reset());
      dispatch(resetLead());
      dispatch(getAllClients());
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setFilteredClients(
      clients?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );

    //get unique data
  }, [keyword, clients]);

  const columns = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "Address",
      label: "Address",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "DOB",
      label: "DOB",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "package",
      label: "package",
    },
    {
      name: "RMR",
      label: "RMR",
    },
    // {
    //   name: "Service Date",
    //   label: "Service Date",
    // },
  ];

  const handleDelete = () => {
    dispatch(deleteClient(clientData._id));
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredClients.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filteredClients.length / recordsPerPage);

  function stringTruncator(text, count) {
    return text?.slice(0, count) + (text?.length > count ? "..." : "");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Clients" pageTitle="Clients" />
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <SearchInput
                    autoComplete="on"
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </div>
                <div className="d-flex mb-3 gap-3"></div>
              </div>
            </CardHeader>
            <CardBody>
              <table className="table table-striped table-lg table-responsive-lg table-hover">
                <thead>
                  <tr>
                    {columns.map((d, i) => (
                      <th key={i}>{d.label}</th>
                    ))}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr className="text-center">
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : currentRecords?.length <= 0 ? (
                    <tr>
                      <td colSpan={9} className="text-center fw-bolder py-5">
                        No Data found
                      </td>
                    </tr>
                  ) : (
                    currentRecords?.map((client, i) => (
                      <tr key={client._id}>
                        <td>
                          {stringTruncator(
                            `${client?.firstname} ${client?.lastname}`,
                            20
                          )}
                        </td>
                        <td>
                          {stringTruncator(
                            `${client?.street}, ${client?.city}, ${client?.state}, ${client?.zipCode}`,
                            20
                          )}
                        </td>
                        <td className="text-nowrap">{client?.phone}</td>
                        <td>{moment(client?.dob).format("MMMM Do, YYYY")}</td>
                        <td>
                          <Badge color="success">{client?.status}</Badge>
                        </td>
                        <td>{client?.package?.name}</td>

                        <td>{client?.package?.amount}</td>

                        <td>
                          <div className="dropdown d-md-flex profile-1">
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="dropdown"
                              className="nav-link mx-auto leading-none d-flex px-1"
                            >
                              <i className="ri-more-2-line"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                              <Link
                                to={`/clients/${client?._id}`}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-eye-line"></i>{" "}
                                View Details
                              </Link>
                              <button
                                onClick={() => {
                                  dispatch(getLead(client?.lead));
                                  setShowEditLead(!showEditLead);
                                }}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-edit-line"></i>{" "}
                                Edit
                              </button>
                              {/* <button
                                onClick={() => {}}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-time-line"></i>{" "}
                                Schedule
                              </button> */}
                              <button
                                onClick={() => {
                                  setshowDelete(!showDelete);
                                  setClientData(client);
                                }}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-delete-bin-6-line"></i>{" "}
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </tfoot>
              </table>
            </CardBody>
          </Card>
        </Container>
      </div>
      <DeleteModal
        show={showDelete}
        onCloseClick={() => setshowDelete(!showDelete)}
        onDeleteClick={handleDelete}
      />
      {!isLoadingLead && lead && (
        <EditLeadModal
          show={showEditLead}
          lead={lead}
          onCloseClick={() => setShowEditLead(!showEditLead)}
        />
      )}
    </React.Fragment>
  );
}

export default Clients;

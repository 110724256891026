import { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";

const Widget = ({ totalClients, averageRevenue, totalLeads, onClick }) => {
  return (
    <Fragment>
      <Row className="row-cols-lg-5">
        <Col>
          <Card
            className="card-animate"
            onClick={() => onClick("Total Clients")}
          >
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0  text-nowrap text-truncate">
                    Total Clients
                  </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      <CountUp
                        start={0}
                        end={totalClients}
                        decimals={0}
                        duration={4}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-danger mb-0">
                      <i className="ri-arrow-down-line align-middle"></i>
                      view more...
                    </span>{" "}
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-danger rounded-circle fs-2">
                      <FeatherIcon icon="briefcase" className="text-info" />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card
            className="card-animate"
            // onClick={() => onClick("Average Monthly rate per client")}
          >
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0 text-truncate">
                    Average Monthly rate per client
                  </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    $
                    <span className="counter-value" data-target="33.48">
                      <CountUp
                        start={0}
                        end={averageRevenue}
                        decimals={2}
                        duration={4}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-success mb-0">
                      <i className="ri-arrow-up-line align-middle"></i> view
                      more...
                    </span>{" "}
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-success rounded-circle fs-2">
                      <i className="ri-money-dollar-box-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* 
        <Col>
          <Card className="card-animate" onClick={() => onClick("Customers")}>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Customers</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      <CountUp
                        start={0}
                        end={totalCustomers}
                        decimals={0}
                        duration={4}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-danger mb-0">
                      <i className="ri-arrow-down-line align-middle"></i>
                      view more...
                    </span>{" "}
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-primary rounded-circle fs-2">
                      <FeatherIcon icon="users" className="text-info" />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
        <Col>
          <Card className="card-animate" onClick={() => onClick("Leads")}>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Total Leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value">
                      <CountUp
                        start={0}
                        end={totalLeads}
                        decimals={0}
                        duration={4}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-success mb-0">
                      <i className="ri-arrow-up-line align-middle"></i>
                      view more...
                    </span>{" "}
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded-circle bg-soft-warning fs-2">
                      <FeatherIcon
                        icon="credit-card"
                        className="text-success"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Widget;

import React, { useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Label,
  CardHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import {
  getSendGridSettings,
  getStripeSettings,
  getTwilioSettings,
  updatesettings,
} from "../../redux/settings/setting.slices";

const SettingsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { twilio, sendgrid, stripe, isSuccess, isLoading } = useSelector(
    (state) => state.settings
  );

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  useEffect(() => {
    dispatch(getTwilioSettings({ setting_type: "twilio" }));
    dispatch(getSendGridSettings({ setting_type: "sendgrid" }));
    dispatch(getStripeSettings({ setting_type: "stripe" }));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("successfully updated");
      dispatch(getTwilioSettings({ setting_type: "twilio" }));
      dispatch(getSendGridSettings({ setting_type: "sendgrid" }));
      dispatch(getStripeSettings({ setting_type: "stripe" }));
    }
  }, [dispatch, isSuccess]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      twilio_sid: twilio?.meta?.twilio_sid,
      twilio_number: twilio?.meta?.twilio_number,
      twilio_token: twilio?.meta?.twilio_token,
    },
  });

  const {
    handleSubmit: handleSendgrid,
    register: registerSendgrid,
    formState: { errors: errorSendgrid },
  } = useForm({
    defaultValues: {
      api_key: sendgrid?.meta?.api_key,
      official_email: sendgrid?.meta?.official_email,
    },
  });

  const {
    handleSubmit: handleStripe,
    register: registerStripe,
    formState: { errors: errorsStripe },
  } = useForm({
    defaultValues: {
      stripe_sk: stripe?.meta?.stripe_sk,
    },
  });

  const handleUpdateTwilio = (data) => {
    dispatch(
      updatesettings({
        setting_type: "twilio",
        meta: {
          ...data,
        },
      })
    );
  };
  const handleUpdateSendGrid = (data) => {
    dispatch(
      updatesettings({
        setting_type: "sendgrid",
        meta: {
          ...data,
        },
      })
    );
  };
  const handleUpdateStripe = (data) => {
    dispatch(
      updatesettings({
        setting_type: "stripe",
        meta: {
          ...data,
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <>
            <div className="d-flex justify-content-between my-2">
              <div></div>
              {/* <button type="submit" className="btn btn-outline-dark">
                {isLoading ? <Spinner size={"sm"} /> : "Update"}
              </button> */}
            </div>
            <Col md={12}>
              <form onSubmit={handleSubmit(handleUpdateTwilio)}>
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <h4 className="card-title mb-4">SMS settings</h4>
                    <button type="submit" className="btn btn-outline-dark">
                      {isLoading ? <Spinner size={"sm"} /> : "Update"}
                    </button>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="form-group col-lg-4">
                        <Label className="form-label">Twilio SID</Label>
                        <input
                          className="form-control"
                          placeholder="Enter Twilio SID"
                          type="text"
                          {...register("twilio_sid")}
                          defaultValue={twilio?.meta?.twilio_sid}
                        />
                        {errors.twilio_sid && (
                          <span className="text-danger">Provide your name</span>
                        )}
                      </div>
                      <div className="form-group col-lg-4">
                        <Label className="form-label">Twilio Token</Label>

                        <input
                          className="form-control"
                          placeholder="Enter twilio token"
                          type="text"
                          {...register("twilio_token")}
                          defaultValue={twilio?.meta?.twilio_token}
                        />
                        {errors.twilio_token && (
                          <span className="text-danger">
                            Provide your twilio token
                          </span>
                        )}
                      </div>
                      <div className="form-group col-lg-4">
                        <Label className="form-label">Twilio Phone</Label>
                        <input
                          defaultValue={twilio?.meta?.twilio_number}
                          className="form-control"
                          placeholder="Enter phone number"
                          type="phone"
                          {...register("twilio_number")}
                        />
                        {errors.twilio_number && (
                          <span className="text-danger">
                            Provide your twilio phone
                          </span>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </form>
            </Col>
            <Col md={12}>
              <form onSubmit={handleSendgrid(handleUpdateSendGrid)}>
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <h4 className="card-title mb-4">Email settings</h4>
                    <button type="submit" className="btn btn-outline-dark">
                      {isLoading ? <Spinner size={"sm"} /> : "Update"}
                    </button>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <Label className="form-label">Sendgrid Api key</Label>
                        <input
                          name="api_key"
                          className="form-control"
                          placeholder="send Api Key"
                          type="text"
                          {...registerSendgrid("api_key")}
                          defaultValue={sendgrid?.meta?.api_key}
                        />
                        {errorSendgrid.api_key && (
                          <span className="text-danger">
                            Please Send Grid Api Key is required
                          </span>
                        )}
                      </div>
                      <div className="form-group col-lg-6">
                        <Label className="form-label">Email</Label>
                        <input
                          name="official_email"
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          {...registerSendgrid("official_email")}
                          defaultValue={sendgrid?.meta?.official_email}
                        />
                        {errorSendgrid.official_email && (
                          <span className="text-danger">
                            Please SendGrid Email
                          </span>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </form>
            </Col>

            <Col md={6}>
              <form onSubmit={handleStripe(handleUpdateStripe)}>
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <h4 className="card-title mb-4">Payment settings</h4>
                    <button type="submit" className="btn btn-outline-dark">
                      {isLoading ? <Spinner size={"sm"} /> : "Update"}
                    </button>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="form-group col-12">
                        <Label className="form-label">Stripe secret key</Label>
                        <input
                          name="stripe_sk"
                          className="form-control"
                          placeholder="stripe secret key"
                          type="text"
                          {...registerStripe("stripe_sk")}
                          defaultValue={stripe?.meta.stripe_sk}
                        />
                        {errorsStripe.stripe_sk && (
                          <span className="text-danger">
                            Please Stripe Secret Key is required
                          </span>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </form>
            </Col>
          </>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingsScreen;

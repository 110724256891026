import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "settings/";

// Get twilio Settings
const getTwilioSettings = async (token, setting) => {
  const res = await axios.post(API_URL + "find", setting, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get sendGrid Settings
const getSendGridSettings = async (token, setting) => {
  const res = await axios.post(API_URL + "find", setting, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};
// Get stripe Settings
const getStripeSettings = async (token, setting) => {
  const res = await axios.post(API_URL + "find", setting, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a Settings
const updateSettings = async (token, SettingsData) => {
  const res = await axios.patch(API_URL, SettingsData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const settingsService = {
  getTwilioSettings,
  getSendGridSettings,
  getStripeSettings,
  updateSettings,
};

export default settingsService;

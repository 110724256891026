import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import packageService from "./package.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  packageData: null,
  packages: [],
};

//GET all packages
export const addPackage = createAsyncThunk(
  "package/add",
  async function (packageData, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await packageService.addPackage(token, packageData);

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET a packages
export const getPackage = createAsyncThunk(
  "package/single",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await packageService.getPackage(token, id);

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//UPDATE a packages
export const updatePackage = createAsyncThunk(
  "package/update",
  async function (packageData, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;

      const res = await packageService.updatePackage(
        token,
        packageData.id,
        packageData.data
      );

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//DELETE a packages
export const deletePackage = createAsyncThunk(
  "package/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;

      const res = await packageService.deletePackage(token, id);

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET all packages
export const getAllPackage = createAsyncThunk(
  "package/all",
  async function (status, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await packageService.getAllPackage(token, status);

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// create slice
const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPackage.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(addPackage.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.packageData = actions.payload;
        state.packages.push(actions.payload);
        state.isSuccess = true;
        state.message = "Package Added Successfully";
      })
      .addCase(addPackage.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(updatePackage.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(updatePackage.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Package Updated Successfully";
      })
      .addCase(updatePackage.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getAllPackage.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getAllPackage.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.packages = actions.payload;
      })
      .addCase(getAllPackage.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(deletePackage.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(deletePackage.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.packages.filter((item) => item._id !== actions.payload._id);
        state.isSuccess = true;
        state.message = "Package Deleted Successfully";
      })
      .addCase(deletePackage.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getPackage.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getPackage.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.packageData = actions.payload;
      })
      .addCase(getPackage.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      });
  },
});

export const { reset } = packageSlice.actions;
export default packageSlice.reducer;

import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Container,
  Button,
  CardBody,
  CardHeader,
  Spinner,
  Card,
} from "reactstrap";
import { toast } from "react-toastify";
import AddManagerModal from "../../Components/managers/AddManagerModal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Common/Pagination";
import SearchInput from "../../Components/SearchInput";
import {
  deleteManager,
  getAllManagers,
  getManager,
  reset,
} from "../../redux/managers/managers.slices";
import DeleteModal from "../../Components/Common/DeleteModal";
import EditManagerModal from "../../Components/managers/EditManagerModal";

function Managers() {
  const dispatch = useDispatch();

  const [showAddNewManager, setshowAddNewManager] = useState(false);
  const [showEditManager, setshowEditManager] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [managerData, setManagerData] = useState(null);
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAllManagers());
  }, []);

  const { isLoading, message, manager, managers, isSuccess } = useSelector(
    (state) => state.managers
  );

  useEffect(() => {
    if (isSuccess) {
      setshowAddNewManager(false);
      setshowDelete(false);
      setshowEditManager(false);
      toast.success(message);
      dispatch(reset());
      dispatch(getAllManagers());
    }
  }, [dispatch, isSuccess, message]);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setFilteredManagers(
      managers?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );

    //get unique data
  }, [keyword, managers]);

  const handleDelete = () => {
    dispatch(deleteManager(managerData?._id));
  };

  const columns = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },

    {
      name: "phone",
      label: "Phone",
    },
    // {
    //   name: "Status",
    //   label: "Status",
    // },
    // {
    //   name: "calendly_link",
    //   label: "Calendly Link",
    // },
  ];

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredManagers?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filteredManagers?.length / recordsPerPage);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Managers" pageTitle="Managers" />

          <Card className="mt-3">
            <CardHeader>
              <div className="d-lg-flex justify-content-between w-100">
                <div className="d-flex gap-3">
                  <SearchInput
                    autoComplete="on"
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </div>
                <div className="d-flex mb-3 gap-3">
                  <Button
                    color="primary"
                    onClick={() => setshowAddNewManager(!showAddNewManager)}
                  >
                    Add New Manager
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <table className="table table-striped table-responsive-sm table-sm table-hover">
                <thead>
                  <tr>
                    {columns.map((d, i) => (
                      <th key={i}>{d.label}</th>
                    ))}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr className="text-center">
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : currentRecords?.length <= 0 ? (
                    <tr>
                      <td colSpan={9} className="text-center fw-bolder py-5">
                        No Data found
                      </td>
                    </tr>
                  ) : (
                    currentRecords?.map((m, i) => (
                      <tr key={i}>
                        <td>
                          {m?.firstname} {m?.lastname}
                        </td>
                        <td>{m?.email}</td>
                        <td>{m?.phone}</td>
                        {/* <td>
                          <span className="badge badge-success-light">
                            {m?.isActive ? "active" : "deactive"}
                          </span>
                        </td> */}
                        {/* <td>{m?.calendly_link}</td> */}
                        <td>
                          <Link to={`/managers/${m?._id}`} className="btn">
                            <i className="ri-eye-line"></i>
                          </Link>
                          <button
                            onClick={() => {
                              setshowEditManager(true);
                              // setManagerData(m);
                              dispatch(getManager(m?._id));
                            }}
                            className="btn"
                          >
                            <i className="ri-edit-line"></i>
                          </button>
                          <button
                            onClick={() => {
                              setshowDelete(!showDelete);
                              setManagerData(m);
                            }}
                            className="btn"
                          >
                            <i className="ri-delete-bin-6-line"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </tfoot>
              </table>
            </CardBody>
          </Card>
        </Container>
      </div>
      <AddManagerModal
        show={showAddNewManager}
        onCloseClick={() => setshowAddNewManager(!showAddNewManager)}
      />
      {!isLoading && manager && (
        <EditManagerModal
          show={showEditManager}
          manager={manager}
          onCloseClick={() => setshowEditManager(!showEditManager)}
        />
      )}

      <DeleteModal
        show={showDelete}
        onCloseClick={() => setshowDelete(!showDelete)}
        onDeleteClick={handleDelete}
      />
    </React.Fragment>
  );
}

export default Managers;

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClient,
  getClient,
  reset,
} from "../../redux/clients/client.slices";
import DeleteModal from "../../Components/Common/DeleteModal";
import EditLeadModal from "../../Components/leads/EditLeadModal";
import { getManager } from "../../redux/managers/managers.slices";
import moment from "moment";
import { getPackage } from "../../redux/packages/package.slices";
import { getLead, reset as resetLead } from "../../redux/leads/lead.slices";
import { toast } from "react-toastify";

export default function ClientDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDelete, setshowDelete] = useState(false);
  const [showEditLead, setShowEditLead] = useState(false);

  const {
    isLoading,
    message: messageClient,
    client,
    isSuccess,
  } = useSelector((state) => state.clients);

  const {
    lead,
    message,
    isSuccess: isSuccessLead,
    isLoading: isLoadingLead,
  } = useSelector((state) => state.leads);

  const { manager } = useSelector((state) => state.managers);
  const { packageData } = useSelector((state) => state.packages);

  useEffect(() => {
    if (isSuccess) {
      setshowDelete(false);
      setShowEditLead(false);
      navigate("/clients");
      toast.success("Client " + messageClient);

      dispatch(reset());
    }
  }, [dispatch, isSuccess, navigate]);

  useEffect(() => {
    if (isSuccessLead) {
      setShowEditLead(false);
      dispatch(reset());
      dispatch(resetLead());
      toast.success("Client " + message);
      dispatch(getClient(id));
    }
  }, [dispatch, id, isSuccessLead]);

  useEffect(() => {
    dispatch(getClient(id));
  }, []);

  useEffect(() => {
    dispatch(getPackage(client?.lead?.package));
    dispatch(getManager(client?.lead?.manager));
  }, [client]);

  const handleDelete = () => {
    dispatch(deleteClient(client?._id));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Client's Detail" pageTitle="Clients" />
        <div className="my-3 d-flex justify-content-between">
          <Link to={"/clients"}>Go back</Link>
          <div className="d-flex mb-3 gap-3">
            <Button
              color="primary"
              onClick={() => {
                dispatch(getLead(client?.lead?._id));
                setShowEditLead(!showEditLead);
              }}
            >
              Edit
            </Button>
            <button
              className="btn btn-outline-dark"
              onClick={() => setshowDelete(true)}
            >
              Delete
            </button>
            <a
              className="btn btn-warning"
              target={"_blank"}
              href={
                client?.agreement === null && client?.agreement?.doc === null
                  ? `https://portal.savvycams.com/contract/${client?._id}`
                  : client?.agreement !== null &&
                    client?.agreement?.doc !== null
                  ? `https://api.savvycams.com/${client?.agreement?.doc}`
                  : `https://portal.savvycams.com/contract/${client?._id}`
              }
              color="warning"
            >
              <i className="ri-file-text-line"></i>
            </a>
          </div>
        </div>
        {isLoading || isLoadingLead ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Card>
            <CardHeader className="bg-indigo-light">
              <div>
                <h2 className="my-3 text-white">
                  {client?.lead?.firstname} {client?.lead?.lastname}'s{" "}
                  <span>details</span>{" "}
                </h2>
                <p>
                  <span>
                    credit score : <Badge>{client?.lead?.credit_grade}</Badge>
                  </span>
                  <span className="ms-3">Dob : </span>{" "}
                  <Badge color="info">
                    {moment(client?.lead?.dob).format("YYYY/MM/DD")}
                  </Badge>
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="4">
                  <h4>Address</h4>
                  <p className="text-muted">
                    {client?.lead?.street}, {client?.lead?.state},{" "}
                    {client?.lead?.zipcode}, {client?.lead?.city}
                  </p>
                </Col>
                <Col lg="4">
                  <h4>Contact</h4>
                  <p className="text-muted">
                    {client?.lead?.phone}, {client?.lead?.email}
                  </p>
                </Col>
                <Col lg="4">
                  <h4>Recurring Rate</h4>
                  <p className="text-muted">${packageData?.amount}</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="4">
                  <h4>One Time Fees</h4>
                  <p className="text-muted">
                    $
                    {client?.lead?.onetime_fees?.reduce(
                      (acc, curr) => (acc += parseFloat(curr.feeAmount)),
                      0
                    ) || 0}
                  </p>
                </Col>
                <Col lg="4">
                  <h4>Service Date</h4>

                  <p className="text-muted">
                    {moment(client?.lead?.service_date).format("MMM Do, YYYY")}
                  </p>
                </Col>
                {/* <Col lg="4">
                  <h4>Payment Date</h4>
                  <p className="text-muted">{client?.createdAt}</p>
                </Col> */}
                <Col lg="4">
                  <h4>Package</h4>
                  <p className="text-muted">{packageData?.name}</p>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col lg="4">
                  <h4>Status</h4>
                  <p className="text-success">{client?.status}</p>
                </Col>
                <Col lg="4">
                  <h4>Contract term</h4>
                  <p className="text-danger">
                    {client?.lead?.contract_length} months
                  </p>
                </Col>
                <Col lg="4">
                  <h4>Manager Assigned</h4>
                  <p className="text-muted">
                    {manager?.firstname} {manager?.lastname}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="4">
                  <h4>Home ownership</h4>
                  <p className="text-muted">Yes</p>
                </Col>
                <Col lg="6">
                  <h4>Notes</h4>
                  <p className="text-muted">{client?.lead?.notes}</p>
                </Col>
              </Row>

              <hr />
              <h3 className="text-info my-4">Secondary Account holder</h3>
              <hr />

              <Row>
                <Col lg="4">
                  <h4>Name</h4>
                  {client?.lead?.secondary_name ? (
                    <p className="text-muted">{client?.lead?.secondary_name}</p>
                  ) : (
                    <p>N/A</p>
                  )}
                </Col>

                <Col lg="4">
                  <h4>Phone</h4>
                  {client?.lead?.secondary_phone ? (
                    <p className="text-muted">
                      {client?.lead?.secondary_phone}
                    </p>
                  ) : (
                    <p>N/A</p>
                  )}
                </Col>
                <Col lg="4">
                  <h4>DOB</h4>
                  {client?.lead?.secondary_dob ? (
                    <p className="text-muted">
                      {client?.lead?.secondary_dob &&
                        moment(client?.lead?.secondary_dob).format(
                          "DD/MM/YYYY"
                        )}
                    </p>
                  ) : (
                    <p>N/A</p>
                  )}
                </Col>
              </Row>

              <hr />
            </CardBody>
          </Card>
        )}
      </Container>
      {!isLoadingLead && lead && (
        <EditLeadModal
          show={showEditLead}
          lead={lead}
          onCloseClick={() => setShowEditLead(false)}
        />
      )}

      <DeleteModal
        show={showDelete}
        onCloseClick={() => setshowDelete(!showDelete)}
        onDeleteClick={handleDelete}
      />
    </div>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth.slices";
import packageSlice from "./packages/package.slices";
import configurationSlice from "./configurations/configuration.slices";
import leadSlice from "./leads/lead.slices";
import clientSlice from "./clients/client.slices";
import settingSlice from "./settings/setting.slices";
import managersSlice from "./managers/managers.slices";
import servicesSlice from "./services/services.slices";
import userSlice from "./users/user.slices";
import stripesSlices from "./stripes/stripes.slices";

const store = configureStore({
  reducer: {
    auth: authSlice,
    packages: packageSlice,
    leads: leadSlice,
    clients: clientSlice,
    managers: managersSlice,
    services: servicesSlice,
    stripes: stripesSlices,
    users: userSlice,
    settings: settingSlice,
    configurations: configurationSlice,
  },
});

export default store;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logout,
  reset,
  setNewPassword,
  updateProfile,
} from "../../redux/auth/auth.slices";
import { toast } from "react-toastify";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleProfile,
    register: registerProfile,
    formState: { errors: errorsProfile },
  } = useForm();

  const {
    user,
    isSuccess,
    isError,
    isLoading,
    message,
    isPasswordResetLoading,
    isPasswordResetSuccess,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isPasswordResetSuccess) {
      toast.success("Successfully updated");
      setValue("current_password", "");
      setValue("new_password", "");
      setValue("confirm_password", "");
    }

    if (isError) {
      toast.error(message);
    }
    dispatch(reset());
  }, [isError, isSuccess, dispatch, message, isPasswordResetSuccess]);

  const handleUpdateProfile = (data) => {
    dispatch(updateProfile(data));
  };

  const handleSetPassword = (data) => {
    dispatch(setNewPassword({ email: user?.email, ...data }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {isError && isError ? (
                <Alert color="danger">{message}</Alert>
              ) : null}
              {isSuccess ? (
                <Alert color="success">
                  Username Updated To {user?.firstname} {user?.lastname}
                </Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-lg-flex d-sm-block text-center-sm">
                    <div className="mx-3">
                      <img
                        src={
                          user?.profile_picture ||
                          "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                        }
                        alt=""
                        height={200}
                        width={200}
                        className="avatar-xl rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>
                          {user?.firstname + " " + user?.lastname || "Admin"}
                        </h5>
                        <p className="mb-1">Email Id : {user?.email}</p>
                        {/* <p className="mb-0">Id No : #{user?._id}</p> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <h4 className="card-title mb-4">Update User Profile</h4>
              <Card>
                <CardBody>
                  <form
                    className="form-horizontal"
                    onSubmit={handleProfile(handleUpdateProfile)}
                  >
                    <div className="form-group">
                      <Label className="form-label">First Name</Label>
                      <input
                        defaultValue={user?.firstname}
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        {...registerProfile("firstname", { required: true })}
                      />
                      {errors.firstname && (
                        <span className="text-danger">
                          Provide your last name
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Last Name</Label>
                      <input
                        defaultValue={user?.lastname}
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        {...registerProfile("lastname", { required: true })}
                      />
                      {errors.lastname && (
                        <span className="text-danger">
                          Provide your last name
                        </span>
                      )}
                    </div>
                    <div className="form-group mt-4">
                      <Label className="form-label">User Email</Label>
                      <input
                        defaultValue={user?.email}
                        className="form-control"
                        placeholder="Enter admin email"
                        type="email"
                        {...registerProfile("email", { required: true })}
                      />
                      {errors.email && (
                        <span className="text-danger">Provide your email</span>
                      )}
                    </div>
                    <div className="mt-4">
                      <Button type="submit" color="primary">
                        {isLoading ? "loading..." : "Update User"}
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <h4 className="card-title mb-4">Change Password</h4>

              <Card>
                <CardBody>
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(handleSetPassword)}
                  >
                    <div className="form-group">
                      <Label className="form-label">Current password</Label>
                      <input
                        name="current_password"
                        className="form-control"
                        placeholder="New password"
                        type="password"
                        {...register("current_password", { required: true })}
                      />
                      {errors.new_password && (
                        <span className="text-danger">
                          Please current password
                        </span>
                      )}
                    </div>
                    <div className="form-group mt-4">
                      <Label className="form-label">New password</Label>
                      <input
                        name="new_password"
                        className="form-control"
                        placeholder="New password"
                        type="password"
                        {...register("new_password", { required: true })}
                      />
                      {errors.new_password && (
                        <span className="text-danger">
                          Please confirm password
                        </span>
                      )}
                    </div>
                    <div className="form-group mt-4">
                      <Label className="form-label">Confirm password</Label>
                      <input
                        name="confirm_password"
                        className="form-control"
                        placeholder="Confirm password"
                        type="password"
                        {...register("confirm_password", { required: true })}
                      />
                      {errors.confirm_password && (
                        <span className="text-danger">
                          Please confirm password
                        </span>
                      )}
                    </div>
                    <div className="mt-4">
                      <Button type="submit" color="danger">
                        {isPasswordResetLoading
                          ? "loading..."
                          : "Update Password"}
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import stripeService from "./stripes.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  customer: null,
  customers: [],
  capturedTransactions: [],
  projectedTransactions: {},
};

//GET all stripe customers

export const getCustomers = createAsyncThunk(
  "stripes/customers",
  async function (__, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await stripeService.getCustomers(token);

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET all stripe cpatured revenues

export const getAllCapturedTransactions = createAsyncThunk(
  "stripes/captured",
  async function (__, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await stripeService.getAllCapturedTransactions(token);
      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET all stripe projected revenues

export const getAllProjectedTransactions = createAsyncThunk(
  "stripes/projected",
  async function (__, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await stripeService.getAllProjectedTransactions(token);
      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// create slice
const stripeSlice = createSlice({
  name: "stripes",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.customers = actions.payload;
      })
      .addCase(getCustomers.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getAllCapturedTransactions.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getAllCapturedTransactions.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.capturedTransactions = actions.payload;
      })
      .addCase(getAllCapturedTransactions.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getAllProjectedTransactions.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getAllProjectedTransactions.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.projectedTransactions = actions.payload;
      })
      .addCase(getAllProjectedTransactions.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      });
  },
});

export const { reset } = stripeSlice.actions;
export default stripeSlice.reducer;

import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllLeads } from "../../redux/leads/lead.slices";
import { useDispatch, useSelector } from "react-redux";

const UploadLeadModal = ({ show, onDeleteClick, onCloseClick }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    let fd = new FormData();
    fd.append("file", acceptedFiles);
    await axios
      .post(process.env.REACT_APP_API_URL + "uploads/csv", fd, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        dispatch(getAllLeads());
        toast.success("Successfully uploaded");
      })
      .catch((error) => toast.error(error.toString()))
      .finally(() => {
        setLoading(false);
        onCloseClick();
      });
  }, []);

  const handleChange = (file) => {
    onDrop(file);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Upload Leads CSV</h4>
            <p className="text-muted mx-4 mb-0">Upload a populated csv here</p>
            <div className="my-4">
              <FileUploader
                handleChange={handleChange}
                name="file"
                // types={fileTypes}
              />
            </div>
          </div>
          <h1>Or</h1>
          <a href="/Sample_CSV.csv" download>
            Download sample csv
          </a>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={handleChange}
          >
            {loading ? <Spinner size={"sm"} /> : "Upload"}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

UploadLeadModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default UploadLeadModal;

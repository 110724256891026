import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, reset } from "../redux/auth/auth.slices";

const TheHeader = () => {
  // const { toggleTheme } = useContext(ThemeContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
  };

  return (
    <div className="app-header header sticky">
      <div className="container-fluid main-container">
        <div className="d-flex align-items-center">
          <a
            aria-label="Hide Sidebar"
            className="app-sidebar__toggle"
            data-bs-toggle="sidebar"
            href="javascript:void(0);"
          ></a>
          <div className="responsive-logo">
            <Link to="/" className="header-logo">
              <img
                src="./assets/images/brands/logos/mobile.png"
                className="mobile-logo logo-1"
                alt="logo"
                style={{ height: "40px" }}
              />
              <img
                src="./assets/images/brand/savvycams.png"
                className="mobile-logo dark-logo-1"
                alt="logo"
                style={{ height: "40px" }}
              />
            </Link>
          </div>

          <Link className="logo-horizontal" to="/">
            <img
              src="./assets/images/brand/savvycams.png"
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              src="./assets/images/brand/savvycams.png"
              className="header-brand-img light-logo1"
              height={"20px"}
              alt="logo"
            />
          </Link>
          <div className="d-flex order-lg-2 ms-auto header-right-icons">
            <button
              className="navbar-toggler navresponsive-toggler d-lg-none ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent-4"
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fe fe-more-vertical text-dark"></span>
            </button>
            <div className="navbar navbar-collapse responsive-navbar p-0">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2">
                  <div className="dropdown d-block d-lg-none">
                    <a
                      href="javascript:void(0);"
                      className="nav-link icon"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fe fe-search"></i>
                    </a>
                    {/* <div className="dropdown-menu header-search dropdown-menu-start">
                      <div className="input-group w-100 p-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search...."
                        />
                        <div className="input-group-text btn btn-primary">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div
                    // onClick={toggleTheme}
                    className="dropdown d-md-flex"
                  >
                    <div className="nav-link icon theme-layout nav-link-bg layout-setting">
                      <span className="dark-layout">
                        <i className="fe fe-moon"></i>
                      </span>
                      <span className="light-layout">
                        <i className="fe fe-sun"></i>
                      </span>
                    </div>
                  </div> */}

                  <div className="dropdown d-md-flex">
                    <div className="nav-link icon full-screen-link nav-link-bg">
                      <i
                        className="fe fe-minimize fullscreen-button"
                        id="myvideo"
                      ></i>
                    </div>
                  </div>

                  <div className="dropdown d-md-flex profile-1">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      className="nav-link leading-none d-flex px-1"
                    >
                      <span>
                        <img
                          src={
                            "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                          }
                          alt="profile-user"
                          className="avatar  profile-user brround cover-image"
                        />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <div className="drop-heading">
                        <div className="text-center">
                          <h5 className="text-dark mb-0">
                            {user?.firstname} {user?.lastname}
                          </h5>
                          <small className="text-muted">Administrator</small>
                        </div>
                      </div>
                      <Link
                        to={"/profile"}
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <i className="dropdown-icon fe fe-user"></i>
                        Profile
                      </Link>
                      {/* <Link
                        to={"/settings"}
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <i className="dropdown-icon fe fe-settings"></i>
                        Settings
                      </Link> */}
                      <div className="dropdown-divider m-0"></div>

                      <div
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={handleLogout}
                      >
                        <i className="dropdown-icon fe fe-alert-circle"></i>
                        Sign out
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheHeader;

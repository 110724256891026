import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "services/";

// Add a new Service
const addService = async (token, serviceData) => {
  const res = await axios.post(API_URL, serviceData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get all Service
const getAllService = async (token, status) => {
  const res = await axios.get(API_URL + "?q=" + status, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a Service
const getService = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a Service
const updateService = async (token, id, ServiceData) => {
  const res = await axios.patch(API_URL + id, ServiceData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// Delete a Service
const deleteService = async (token, id) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const ServiceService = {
  addService,
  getAllService,
  getService,
  deleteService,
  updateService,
};

export default ServiceService;

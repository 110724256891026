const _navManager = [
  {
    label: "Menu",
    isHeader: true,
  },
  {
    icon: "ri-apps-2-line",
    id: "home",
    label: "Dashboard",
    link: "/dashboard",
    parentId: "dashboard",
  },
  {
    label: "Leads & Clients",
    isHeader: true,
  },
  {
    icon: "ri-dashboard-2-line",
    id: "Leads",
    label: "Leads",
    link: "/leads",
    parentId: "apps",
  },

  {
    icon: "ri-secure-payment-line",
    id: "Clients",
    label: "Clients",
    link: "/clients",
    parentId: "apps",
  },

  {
    label: "Settings",
    isHeader: true,
  },
  {
    icon: "ri-settings-2-line",
    id: "Settings",
    label: "Settings",
    link: "/settings/esigns",
    parentId: "apps",
  },
];

export default _navManager;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import managersService from "./managers.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  manager: null,
  managers: [],
  message: "",
};

//get all managers
export const addManagers = createAsyncThunk(
  "managers/add",
  async function (managers, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await managersService.addManagers(token, managers);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all managers
export const getAllManagers = createAsyncThunk(
  "managers/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await managersService.getAllManagers(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get a managers
export const getManager = createAsyncThunk(
  "managers/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await managersService.getManager(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get a managers
export const deleteManager = createAsyncThunk(
  "managers/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await managersService.deleteManager(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get a managers
export const updateManager = createAsyncThunk(
  "managers/update",
  async function (manager, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await managersService.updateManager(
        manager.id,
        manager.data,
        token
      );
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create managers slice

const managersSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addManagers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addManagers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.managers.push(action.payload);
        state.isSuccess = true;
        state.message = "Manager Added Successfully";
      })
      .addCase(addManagers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(getAllManagers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllManagers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.managers = action.payload;
      })
      .addCase(getAllManagers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getManager.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.manager = action.payload;
      })
      .addCase(getManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteManager.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.manager = action.payload;
        state.isSuccess = true;
        state.message = "Manager Deleted Successfully";
      })
      .addCase(deleteManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(updateManager.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.manager = action.payload;
        state.isSuccess = true;
        state.message = "Manager Updated Successfully";
      })
      .addCase(updateManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = managersSlice.actions;
export default managersSlice.reducer;

import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { updatePackage } from "../../redux/packages/package.slices";

const EditPackageModal = ({ show, packageData, onCloseClick }) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.packages);

  const handleSave = () => {
    dispatch(
      updatePackage({
        id: packageData?._id,
        data: { publish: !packageData?.publish },
      })
    );
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div>
          <h3 className="text-info">
            {packageData?.publish ? "Unpublish package" : "Publish package"}
          </h3>
          <hr />
          <h5 className="my-6">Do you want to continue with the action?</h5>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
              onClick={handleSave}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Yes"}
            </button>
          </div>
        </div>
        {/* <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h5 className="mb-4 text-muted">
              Edit <span className="text-success">{packageData?.name}'s</span>{" "}
              package
            </h5>
            <Row>
              <FormGroup>
                <label htmlFor="name">Package Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  defaultValue={packageData?.name}
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-danger">Package name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="amount">Amount</label>
                <input
                  type="text"
                  name="amount"
                  className="form-control"
                  onKeyDown={preventMinus}
                  defaultValue={packageData?.amount}
                  {...register("amount", { required: true })}
                />
                {errors.amount && (
                  <span className="text-danger">Amount is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="price_id">Stripe ID</label>
                <input
                  type="text"
                  name="price_id"
                  className="form-control"
                  min={"0"}
                  defaultValue={packageData?.price_id}
                  {...register("price_id")}
                  disabled
                />
              </FormGroup>
            </Row>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form> */}
      </ModalBody>
    </Modal>
  );
};

EditPackageModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default EditPackageModal;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "stripes/";

// Get a Customers
const getCustomers = async (token) => {
  const res = await axios.get(API_URL + "customers", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a Customers
const getAllCapturedTransactions = async (token) => {
  const res = await axios.get(API_URL + "transactions/captured", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a Customers
const getAllProjectedTransactions = async (token) => {
  const res = await axios.get(API_URL + "transactions/projected", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

const StripeService = {
  getCustomers,
  getAllCapturedTransactions,
  getAllProjectedTransactions,
};

export default StripeService;

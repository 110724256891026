const _nav = [
  {
    label: "Menu",
    isHeader: true,
  },
  {
    icon: "ri-apps-2-line",
    id: "home",
    label: "Dashboard",
    link: "/dashboard",
    parentId: "dashboard",
  },
  {
    label: "Leads & Clients",
    isHeader: true,
  },
  {
    icon: "ri-dashboard-2-line",
    id: "Leads",
    label: "Leads",
    link: "/leads",
    parentId: "apps",
  },

  {
    icon: "ri-secure-payment-line",
    id: "Clients",
    label: "Clients",
    link: "/clients",
    parentId: "apps",
  },
  {
    label: "Packages",
    isHeader: true,
  },
  {
    icon: "ri-file-copy-2-line",
    id: "Packages",
    label: "Packages",
    link: "/packages",
    parentId: "apps",
  },
  // {
  //   icon: "ri-file-copy-2-line",
  //   id: "Schedules",
  //   label: "Schedules",
  //   link: "/schedules",
  //   parentId: "apps",
  // },

  {
    label: "Services and Managers",
    isHeader: true,
  },

  {
    icon: "ri-account-circle-line",
    id: "Managers",
    label: "Managers",
    link: "/managers",
    parentId: "apps",
  },
  {
    icon: "ri-account-circle-line",
    id: "Services",
    label: "One time fees",
    link: "/services",
    parentId: "apps",
  },
  {
    label: "Settings",
    isHeader: true,
  },
  {
    icon: "ri-settings-2-line",
    id: "Settings",
    label: "Settings",
    link: "/settings",
    parentId: "apps",
  },
];

export default _nav;

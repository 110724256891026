import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "managers/";

//add new Managers
const addManagers = async (token, manager) => {
  const res = await axios.post(API_URL, manager, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// GET all Managers
const getAllManagers = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// GET a Manager
const getManager = async (id, token) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};
// GET a Managers
const deleteManager = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Manager
const updateManager = async (id, manager, token) => {
  const res = await axios.patch(API_URL + id, manager, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const managersService = {
  addManagers,
  getAllManagers,
  getManager,
  updateManager,
  deleteManager,
};

export default managersService;

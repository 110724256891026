import React from 'react'
import { useNavigate } from 'react-router-dom'

const _404 = () => {
  const navigate = useNavigate()

  return (
    <div className='error-bg'>
      <div className='page'>
        <div className='page-content error-page error2'>
          <div className='container text-center'>
            <div className='error-template'>
              <h1 className='display-1 text-dark mb-2'>
                404<span className='fs-20'>error</span>
              </h1>
              <h5 className='error-details text-dark'>
                Sorry, an error has occured, Requested page not found!
              </h5>
              <div className='text-center'>
                <button
                  className='btn btn-primary mt-5 mb-5'
                  onClick={() => navigate(-1)}
                >
                  <i className='fa fa-long-arrow-left'></i> Back to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default _404

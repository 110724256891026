const TheFooter = () => {
  return (
    <footer
      className="footer position-fixed bottom-0 z-50"
      style={{
        width: "100%",
        zIndex: 9,
      }}
    >
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 text-center">
            Copyright © <span id="year"></span>
            <a href="#" className="ms-2 text-success">
              Savvycams
            </a>
            . Designed with
            <span className="fa fa-heart text-danger"></span> by
            <a href="#"> Invennico </a> All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default TheFooter;

import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { updateService } from "../../redux/services/services.slices";

const EditServiceModal = ({ show, onCloseClick, service }) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.services);

  const handleSave = () => {
    dispatch(
      updateService({ id: service?._id, data: { publish: !service?.publish } })
    );
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div>
          <h3 className="text-info">
            {service?.publish ? "Unpublish service fee" : "Publish service fee"}
          </h3>
          <hr />
          <h5 className="my-6">Do you want to continue with the action?</h5>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
              onClick={handleSave}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Yes"}
            </button>
          </div>
        </div>
        {/* <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h5 className="mb-4 text-muted">Add new Service</h5>
            <Row>
              <FormGroup>
                <label htmlFor="name">Service Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  defaultValue={service?.name}
                  {...register("name")}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="amount">Amount</label>
                <input
                  type="text"
                  name="amount"
                  className="form-control"
                  onKeyDown={preventMinus}
                  defaultValue={service?.amount}
                  {...register("amount")}
                />
              </FormGroup>
            </Row>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form> */}
      </ModalBody>
    </Modal>
  );
};

EditServiceModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default EditServiceModal;

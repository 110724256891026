import React, { useRef, useState } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Label,
  CardHeader,
  Row,
  CardFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";

const SettingsManagerScreen = () => {
  const { user } = useSelector((state) => state.auth);

  const [calendly_link, setcalendly_link] = useState(user?.calendly_link);
  const [imageURL] = useState(user?.imageURL);

  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();

  // const save = () => {
  //   setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  // };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      // console.log(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

      await axios.patch(
        process.env.REACT_APP_API_URL + "users/profile",
        {
          signature:
            sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") ||
            imageURL,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      toast.success("successfully signed");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave2 = async (e) => {
    e.preventDefault();
    try {
      // console.log(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

      await axios.patch(
        process.env.REACT_APP_API_URL + "users/profile",
        {
          calendly_link,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      toast.success("successfully signed");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
          // onSubmit={handleSubmit(handleUpdate)}
          >
            <Row>
              <Col md={6}>
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <h4 className="card-title mb-4">Esign settings</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="form-group col-12">
                        {user && user?.signature ? (
                          <img
                            src={user?.signature}
                            alt=""
                            className="border p-3 h-20 w-60"
                            // onClick={toggle2}
                          />
                        ) : (
                          <SignatureCanvas
                            penColor="blue"
                            ref={sigCanvas}
                            canvasProps={{
                              className: "signatureCanvas",
                              placeholder: "please sign here",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn text-center shadow text-danger btn-outline-danger"
                        onClick={clear}
                        disabled={user?.signature}
                        hidden={user?.signature}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-primary text-center shadow"
                        onClick={handleSave}
                        disabled={user?.signature}
                        hidden={user?.signature}
                      >
                        Adopt
                      </button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <h4 className="card-title mb-4">Calendly settings</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="form-group col-12">
                        <Label className="form-label">
                          Update calendly link
                        </Label>
                        <input
                          type={"url"}
                          className="form-control"
                          placeholder="e.g. https://calendly.com/jordan/"
                          value={calendly_link}
                          onChange={(e) => setcalendly_link(e.target.value)}
                        />
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex justify-content-between my-2">
                      <div></div>
                      <button
                        onClick={handleSave2}
                        className="btn btn-outline-dark"
                      >
                        Update
                      </button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingsManagerScreen;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "packages/";

// Add a new package
const addPackage = async (token, packageData) => {
  const res = await axios.post(API_URL, packageData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get all Package
const getAllPackage = async (token, status) => {
  const res = await axios.get(API_URL + "?q=" + status, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a package
const getPackage = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

// Get a package
const updatePackage = async (token, id, packageData) => {
  const res = await axios.patch(API_URL + id, packageData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// Delete a package
const deletePackage = async (token, id) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const packageService = {
  addPackage,
  getAllPackage,
  getPackage,
  deletePackage,
  updatePackage,
};

export default packageService;

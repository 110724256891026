import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormGroup, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addPackage, reset } from "../../redux/packages/package.slices";

const AddPackageModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const { isLoading, isSuccess } = useSelector((state) => state.packages);

  useEffect(() => {
    if (isSuccess) {
      // onCloseClick();
      setValue("name", "");
      // setValue("stripe_plan_id", "");
      setValue("amount", 0);
      // dispatch(reset());
    }
  }, [isLoading, isSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleSave = (data) => {
    dispatch(addPackage({ ...data }));
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    const keyCode = e.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 190 || // allow period (decimal point)
        excludedKeys.includes(keyCode)
      )
    ) {
      e.preventDefault();
    }

    const inputValue = e.target.value;
    const hasDecimal = inputValue.includes(".");
    if (keyCode === 190 && hasDecimal) {
      // prevent entering more than one decimal point
      e.preventDefault();
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h5 className="mb-4 text-muted">Add new package</h5>
            <Row>
              <FormGroup>
                <label htmlFor="name">Package Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-danger">Package name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="amount">Amount</label>
                <input
                  type="text"
                  name="amount"
                  className="form-control"
                  onKeyDown={preventMinus}
                  min={"0"}
                  {...register("amount", { required: true })}
                />
                {errors.amount && (
                  <span className="text-danger">Amount is required</span>
                )}
              </FormGroup>
            </Row>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

AddPackageModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AddPackageModal;

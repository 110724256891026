import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { reset, sendResetPasswordLink } from "../../redux/auth/auth.slices";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess, isError, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success("Reset link sent you email");
      dispatch(reset());
      navigate("/login");
    }
  }, [dispatch, isSuccess]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleSend = (data) => {
    dispatch(sendResetPasswordLink(data));
  };

  return (
    // <ParticlesAuth>
    <div className="auth-page-content">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center mt-sm-5 mb-5 text-dark-50">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img
                    src="/assets/images/brands/logos/savvycams.png"
                    alt=""
                    className="h-150"
                  />
                </Link>
              </div>
              <p className="mt-3 fs-15 fw-medium">Savvycams Admin Dashboard</p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Forgot Password?</h5>
                  <p className="text-muted">
                    Reset password with Savvycams Admin email
                  </p>

                  <lord-icon
                    src="https://cdn.lordicon.com/rhvddzym.json"
                    trigger="loop"
                    colors="primary:#0ab39c"
                    className="avatar-xl"
                    style={{ width: "120px", height: "120px" }}
                  ></lord-icon>
                </div>

                <Alert
                  className="alert-borderless alert-warning text-center mb-2 mx-2"
                  role="alert"
                >
                  Enter your email and instructions will be sent to you!
                </Alert>
                {isError && (
                  <Alert
                    className="alert-borderless alert-danger text-center mb-2 mx-2"
                    role="alert"
                  >
                    User email does not exist!
                  </Alert>
                )}

                <div className="p-2">
                  <form onSubmit={handleSubmit(handleSend)}>
                    <div className="mb-4">
                      <Label className="form-label">Email</Label>
                      <input
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        {...register("email", { required: true })}
                      />
                      {errors.email && (
                        <span className="text-danger">Email is required</span>
                      )}
                    </div>

                    <div className="text-center mt-4">
                      <button className="btn btn-dark w-100" type="submit">
                        {isLoading ? (
                          <Spinner size={"sm"} />
                        ) : (
                          "Send Reset Link"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>

            <div className="mt-4 text-center">
              <p className="mb-0">
                Wait, I remember my password...{" "}
                <Link
                  to="/login"
                  className="fw-semibold text-primary text-decoration-underline"
                >
                  {" "}
                  Click here{" "}
                </Link>{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    // </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default ForgetPasswordPage;

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import EditLeadModal from "../../Components/leads/EditLeadModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import { deleteLead, getLead, reset } from "../../redux/leads/lead.slices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

export default function LeadDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showEditLead, setShowEditLead] = useState(false);
  const [showDeleteLead, setShowDeleteLead] = useState(false);

  const { isLoading, lead, message, isSuccess } = useSelector(
    (state) => state.leads
  );

  useEffect(() => {
    if (isSuccess) {
      setShowDeleteLead(false);
      setShowEditLead(false);
      dispatch(reset());
      dispatch(getLead(id));
      toast.success("Lead " + message);
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    dispatch(getLead(id));
  }, []);

  const handleDelete = () => {
    dispatch(deleteLead(lead?._id));
    navigate("/leads");
  };

  return (
    <div className="page-content">
      <div className="page">
        <div className="page-main  details">
          <BreadCrumb title="Lead's Detail" pageTitle="Leads" />
          <div className="mb-3 d-md-flex justify-content-between align-items-center my-3">
            <Link to={"/leads"}>Go back</Link>

            <div className="d-flex mb-3 gap-sm-1 gap-lg-3">
              <Button
                color="primary"
                onClick={() => setShowEditLead(!showEditLead)}
              >
                Edit
              </Button>
              <button
                className="btn btn-outline-dark"
                onClick={() => setShowDeleteLead(!showDeleteLead)}
              >
                Delete
              </button>

              <a
                className="btn btn-warning"
                target={"_blank"}
                href={`https://portal.savvycams.com/${lead?._id}`}
                // href={`http://localhost:3001/${lead?._id}`}
                color="warning"
                // onClick={() => setShowUpload(!showUpload)}
              >
                Open
              </a>
            </div>
          </div>
          {isLoading ? (
            <Row className="flex justify-content-center">
              <Spinner />
            </Row>
          ) : (
            <Card>
              <CardHeader className="bg-gray-light">
                <div>
                  <h2 className="my-3 text-white">
                    {lead?.firstname + " " + lead?.lastname}'s{" "}
                    <span>details</span>{" "}
                  </h2>
                  <p className="ms-0">
                    <span>
                      Credit Box :{" "}
                      <Badge>
                        {lead?.credit_grade ? lead?.credit_grade : "-"}
                      </Badge>
                    </span>
                    <span className="ms-3">Dob : </span>{" "}
                    <Badge color="info">
                      {moment(lead?.dob).format("DD/MM/YYYY")}
                    </Badge>
                  </p>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4">
                    <h4>Address</h4>
                    <p>
                      {lead?.street}, {lead?.state}, {lead?.zipcode},{" "}
                      {lead?.city}
                    </p>
                  </Col>
                  <Col lg="4">
                    <h4>Contact</h4>
                    <p>
                      {lead?.phone}, {lead?.email}
                    </p>
                  </Col>
                  <Col lg="4">
                    <h4>Secondary Data</h4>
                    <p>
                      <Badge color="primary">{lead?.secondary_name}</Badge>
                      <Badge color="info">{lead?.secondary_phone}</Badge>
                      <Badge color="secondary">
                        {lead?.secondary_dob &&
                          moment(lead?.secondary_dob).format("DD/MM/YYYY")}
                      </Badge>
                      {!lead?.secondary_dob &&
                        !lead?.secondary_name &&
                        !lead?.secondary_phone &&
                        "N/A"}
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="2">
                    <h4>Home Ownership</h4>
                    <p>{lead?.home_ownership}</p>
                  </Col>
                  <Col lg="2">
                    <h4>Package</h4>
                    <p>{lead?.package?.name}</p>
                  </Col>
                  {/* <Col lg="3">
                  <h4>Subscription Type</h4>
                  <p>{lead?.subscription}</p>
                </Col> */}
                  <Col lg="3">
                    <h4>Service Date</h4>
                    <p>{moment(lead?.service_date).format("DD/MM/YYYY")}</p>
                  </Col>
                  {/* </Row>
              <hr />
              <Row>
                <Col lg="4">
                  <h4>Is Delivered</h4>
                  <p>{lead?.status}</p>
                </Col> */}
                  <Col lg="2">
                    <h4>Status</h4>
                    <p>{lead?.status}</p>
                  </Col>
                  <Col lg="3">
                    <h4>Manager Assigned</h4>
                    <p>
                      {lead?.manager?.firstname} {lead?.manager?.lastname}
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6">
                    <h4>Notes</h4>
                    <p>{lead?.notes ? lead?.notes : "No Notes available"}</p>
                  </Col>

                  {/* <Col lg="6">
                    <h4>Reason rejected</h4>
                    <p>{lead?.notes}</p>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
      {!isLoading && lead && (
        <EditLeadModal
          show={showEditLead}
          lead={lead}
          onCloseClick={() => setShowEditLead(!showEditLead)}
        />
      )}
      <DeleteModal
        show={showDeleteLead}
        onCloseClick={() => setShowDeleteLead(!showDeleteLead)}
        onDeleteClick={handleDelete}
      />
    </div>
  );
}

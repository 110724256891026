import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "configurations/";

// Add a new configuration
const addConfigurations = async (token, config) => {
  const res = await axios.post(API_URL, config, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// Get all configuration
const getConfigurations = async (token) => {
  const res = await axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// Get a configuration
const updateConfigurations = async (token, config) => {
  const res = await axios.patch(API_URL, config, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const categoryService = {
  addConfigurations,
  getConfigurations,
  updateConfigurations,
};

export default categoryService;

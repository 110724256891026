import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Common/Pagination";
import SearchInput from "../../Components/SearchInput";
import AddServiceModal from "../../Components/services/AddServiceModal";
import {
  deleteService,
  getAllservice,
  getService,
  reset,
} from "../../redux/services/services.slices";
import DeleteModal from "../../Components/Common/DeleteModal";
import EditServiceModal from "../../Components/services/EditServiceModal";
import { toast } from "react-toastify";

function Services() {
  const dispatch = useDispatch();

  const [showAddServiceModal, setshowAddServiceModal] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  const [filteredservices, setFilteredservices] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAllservice("all"));
  }, []);

  const { isLoading, services, message, service, isSuccess } = useSelector(
    (state) => state.services
  );

  useEffect(() => {
    if (isSuccess) {
      setshowAddServiceModal(false);
      setshowDelete(false);
      setshowEdit(false);
      setServiceData(null);
      toast.success(message);
      dispatch(reset());
      dispatch(getAllservice("all"));
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setFilteredservices(
      services?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );

    //get unique data
  }, [keyword, services]);

  const columns = [
    {
      name: "_id",
      label: "Service Id",
    },
    {
      name: "name",
      label: "name",
    },

    {
      name: "amount",
      label: "amount",
    },
    {
      name: "Status",
      label: "Status",
    },
  ];

  const handleDelete = () => {
    dispatch(deleteService(serviceData._id));
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredservices.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filteredservices.length / recordsPerPage);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Services" pageTitle="Services" />

          <Card className="mt-3">
            <CardHeader>
              <div className="d-lg-flex justify-content-between w-100">
                <div className="d-flex gap-3">
                  <SearchInput
                    autoComplete="on"
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </div>
                <div className="d-flex mb-3 gap-3">
                  <Button
                    color="primary"
                    onClick={() => setshowAddServiceModal(!showAddServiceModal)}
                  >
                    Add New Service
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <table className="table table-striped table-responsive-sm table-sm table-hover">
                <thead>
                  <tr className="ml-0">
                    {columns.map((d, i) => (
                      <th key={i} className="ms-0 ps-0">
                        {d.label}
                      </th>
                    ))}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr className="text-center">
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : currentRecords.length <= 0 ? (
                    <tr>
                      <td colSpan={9} className="text-center fw-bolder py-5">
                        No Data found
                      </td>
                    </tr>
                  ) : (
                    currentRecords.map((serv, i) => (
                      <tr key={serv?._id}>
                        <td>SE0{i + 1}</td>
                        <td>{serv?.name}</td>
                        <td>{serv?.amount}</td>
                        <td>
                          {serv?.publish ? (
                            <span className="badge badge-success-light">
                              active
                            </span>
                          ) : (
                            <span className="badge badge-danger-light">
                              inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/services/${serv?._id}`}
                            className="btn h3 mt-0 pt-0"
                          >
                            <i className="ri-eye-line"></i>
                          </Link>
                          <button
                            onClick={() => {
                              setshowEdit(!showEdit);
                              // setServiceData(serv);
                              dispatch(getService(serv?._id));
                            }}
                            className="btn h2 mt-0 pt-0"
                          >
                            {serv?.publish ? (
                              <i className="ri-toggle-fill text-success"></i>
                            ) : (
                              <i className="ri-toggle-line "></i>
                            )}
                          </button>
                          {/* <button
                            onClick={() => {
                              setshowDelete(!showDelete);
                              setServiceData(serv);
                            }}
                            className="btn"
                          >
                            <i className="ri-delete-bin-6-line"></i>
                          </button> */}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </tfoot>
              </table>
            </CardBody>
          </Card>
        </Container>
      </div>
      <AddServiceModal
        show={showAddServiceModal}
        onCloseClick={() => setshowAddServiceModal(!showAddServiceModal)}
      />
      {!isLoading && service && (
        <EditServiceModal
          show={showEdit}
          onCloseClick={() => setshowEdit(!showEdit)}
          service={service}
        />
      )}

      <DeleteModal
        show={showDelete}
        onCloseClick={() => setshowDelete(!showDelete)}
        onDeleteClick={handleDelete}
      />
    </React.Fragment>
  );
}

export default Services;

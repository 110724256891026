import { Routes, Route, Navigate } from "react-router-dom";
import TheLayout from "../layouts/TheLayout";

import NotFound from "../views/error/404";
import Login from "../views/Authentication/Login";
import Home from "../views/dashboard/Home";
import Clients from "../views/clients/Clients";
import ClientDetails from "../views/clients/ClientDetails";
import Leads from "../views/leads/Leads";
import LeadDetails from "../views/leads/LeadDetails";
import Packages from "../views/packages/Packages";
import PackageDetails from "../views/packages/PackageDetails";
import Managers from "../views/managers/Managers";
import ManagerDetails from "../views/managers/ManagerDetails";
import SettingsScreen from "../views/settings/Settings";
// import Schedules from "../views/schedules/Schedules";
// import ScheduleDetails from "../views/schedules/ScheduleDetails";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import ForgetPassword from "../views/Authentication/ForgetPassword";
import ResetPassword from "../views/Authentication/ResetPassword";
import UserProfile from "../views/Authentication/Userprofile";
import ServiceDetails from "../views/services/ServicesDetails";
import Services from "../views/services/Services";
import SettingsManagerScreen from "../views/settings/SettingsManager";

const MainRoutes = () => (
  <Routes>
    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<TheLayout />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="leads" element={<Leads />} />
        <Route path="leads/:id" element={<LeadDetails />} />
        <Route path="clients" element={<Clients />} />
        <Route path="clients/:id" element={<ClientDetails />} />
        <Route path="packages" element={<Packages />} />
        <Route path="packages/:id" element={<PackageDetails />} />
        <Route path="managers" element={<Managers />} />
        <Route path="managers/:id" element={<ManagerDetails />} />
        <Route path="settings" element={<SettingsScreen />} />
        <Route path="settings/esigns" element={<SettingsManagerScreen />} />
        <Route path="profile" element={<UserProfile />} />
        {/* <Route path="schedules" element={<Schedules />} />
        <Route path="schedules/:id" element={<ScheduleDetails />} /> */}
        <Route path="services" element={<Services />} />
        <Route path="services/:id" element={<ServiceDetails />} />
      </Route>
    </Route>

    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/login" element={<Login />} />
      <Route path="/reset" element={<ForgetPassword />} />
      <Route path="/reset/:token" element={<ResetPassword />} />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default MainRoutes;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "auth/";
const API_USER_URL = process.env.REACT_APP_API_URL + "users/";

//login a user

const login = async (user) => {
  const response = await axios.post(API_URL + "login", user);
  // if (user.remember_me === true) {
  localStorage.setItem("savvycams", JSON.stringify(response.data.data));
  // }

  return response.data.data;
};

//update profile

const updateProfile = async (user, token) => {
  const response = await axios.patch(API_USER_URL + "profile", user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  localStorage.setItem("savvycams", JSON.stringify(response.data.data));

  return response.data.data;
};

//Set new password for a user

const setNewPassword = async (user, token) => {
  const response = await axios.patch(API_URL + "newpassword", user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//Set new password for a user

const resetPassword = async (user) => {
  const token = user.token;
  const newpassword = user.new_password;
  const response = await axios.post(API_URL + "reset/" + token, {
    password: newpassword,
  });

  return response.data;
};

//Send reset password link to user email
const sendResetPasswordLink = async (data) => {
  const response = await axios.post(API_URL + "reset", data);
  return response.data;
};

//logout a user
const logout = () => {
  localStorage.removeItem("savvycams");
};

const authService = {
  login,
  updateProfile,
  setNewPassword,
  sendResetPasswordLink,
  resetPassword,
  logout,
};

export default authService;

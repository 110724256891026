import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormGroup, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { addManagers } from "../../redux/managers/managers.slices";

const AddManagerModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const { isLoading, isSuccess } = useSelector((state) => state.managers);

  useEffect(() => {
    if (isSuccess) {
      // onCloseClick();
      setValue("firstname", "");
      setValue("lastname", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("password", "");
      // dispatch(reset());
    }
  }, [isLoading, isSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleSave = (data) => {
    dispatch(addManagers({ ...data }));
  };

  const preventMinus = (event) => {
    // Allow only numbers, backspace, delete, plus and minus keys
    if (
      !(
        (
          event.keyCode === 8 || // Backspace
          event.keyCode === 46 || // Delete
          event.keyCode === 43 || // Plus
          event.keyCode === 45 || // Minus
          event.shiftKey ||
          (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers 0-9
          (event.keyCode >= 96 && event.keyCode <= 105)
        ) // Numpad numbers
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h5 className="mb-4 text-muted">Add new manager</h5>
            <Row>
              <FormGroup>
                <label htmlFor="name">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  autoComplete={"off"}
                  {...register("firstname", { required: true })}
                />
                {errors.firstname && (
                  <span className="text-danger">First Name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="name">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  autoComplete={"off"}
                  {...register("lastname", { required: true })}
                />
                {errors.lastname && (
                  <span className="text-danger">Last Name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  autoComplete="off"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-danger">Email is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  onKeyDown={preventMinus}
                  className="form-control"
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <span className="text-danger">Phone is required</span>
                )}
              </FormGroup>

              <FormGroup>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  autoComplete="off"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <span className="text-danger">Password is required</span>
                )}
              </FormGroup>
              {/* <FormGroup>
                <div className="d-flex gap-3 align-items-center my-3">
                  <input
                    type="checkbox"
                    name="isAdmin"
                    value={true}
                    className="form-check"
                    {...register("isAdmin")}
                  />
                  <label htmlFor="isAdmin">Add this account as Admin?</label>
                </div>

                {errors.isAdmin && (
                  <span className="text-danger">isAdmin is required</span>
                )}
              </FormGroup> */}
            </Row>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => {
                setValue("firstname", "");
                setValue("lastname", "");
                setValue("email", "");
                setValue("phone", "");
                setValue("password", "");
                onCloseClick();
              }}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

AddManagerModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AddManagerModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import leadService from "./lead.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  leads: [],
  leads_graph: [],
  lead: null,
};

//add new lead
export const addLead = createAsyncThunk(
  "leads/add",
  async function (lead, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.addLead(token, lead);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all leads
export const getAllLeads = createAsyncThunk(
  "leads/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.getAllLeads(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all leads
export const getCancelledleads = createAsyncThunk(
  "leads/all/cancelled",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.getCancelledleads(token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an lead
export const getLead = createAsyncThunk(
  "leads/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.getLead(token, id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an lead
export const getAdminleadGraph = createAsyncThunk(
  "leads/graph",
  async function (year, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.getAdminleadGraph(token, year);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// PATCH a lead
export const updateLead = createAsyncThunk(
  "leads/update",
  async function (lead, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.updateLead(lead.data, lead.id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// DEL a lead
export const deleteLead = createAsyncThunk(
  "leads/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await leadService.deleteLead(id, token);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for leads
const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLead.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lead = action.payload;
        state.message = "Added Successfully";
      })
      .addCase(addLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getLead.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lead = action.payload;
      })
      .addCase(getLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getAdminleadGraph.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAdminleadGraph.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads_graph = action.payload;
      })
      .addCase(getAdminleadGraph.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getAllLeads.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads = action.payload;
      })
      .addCase(getAllLeads.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getCancelledleads.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCancelledleads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads = action.payload;
      })
      .addCase(getCancelledleads.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(updateLead.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lead = action.payload;
        state.isSuccess = true;
        state.message = "Updated Successfully";
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteLead.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lead = action.payload;
        state.isSuccess = true;
        state.message = "Deleted Successfully";
      })
      .addCase(deleteLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = leadSlice.actions;
export default leadSlice.reducer;

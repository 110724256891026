import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import configurationService from "./configuration.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  cconfiguration: null,
  configurations: [],
};

//GET all categories
export const addConfiguration = createAsyncThunk(
  "configuration/add",
  async function (configuration, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await configurationService.addConfigurations(
        token,
        configuration
      );

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//UPDATE a categories
export const updateConfiguration = createAsyncThunk(
  "cconfiguration/update",
  async function (configuration, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      console.log(configuration);
      const res = await configurationService.updateConfigurations(
        token,
        configuration
      );

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET all categories
export const getConfigurations = createAsyncThunk(
  "cconfiguration/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await configurationService.getConfigurations(token);

      return res;
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// create slice
const configurationSlice = createSlice({
  name: "cconfiguration",
  initialState,
  reducers: {
    reset: (state) => ({
      isLoading: false,
      isError: false,
      isSuccess: false,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addConfiguration.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(addConfiguration.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(addConfiguration.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(updateConfiguration.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(updateConfiguration.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateConfiguration.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getConfigurations.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getConfigurations.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.configurations = actions.payload;
      })
      .addCase(getConfigurations.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      });
  },
});

export const { reset } = configurationSlice.actions;
export default configurationSlice.reducer;

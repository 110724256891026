import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Card,
  Col,
  CardBody,
  CardHeader,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getManager } from "../../redux/managers/managers.slices";
import moment from "moment";

const UserDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getManager(id));
  }, []);

  const { isLoading, manager } = useSelector((state) => state.managers);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Manager's Detail" pageTitle="Managers" />
        <div className="mb-3 d-flex justify-content-between">
          <Link to={"/managers"}>Go back</Link>
          {/* <button onClick={() => {}} className="btn btn-info">
            <i className="ri-pencil-text-line"></i>
          </button> */}
        </div>
        {isLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Card>
            <CardHeader className="bg-soft-dark">
              <h2 className="my-3 text-primary">
                {manager?.firstname} {manager?.lastname}'s <span>details</span>{" "}
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                {/* <Col lg="4">
                  <h4>Created On</h4>
                  <p className="text-muted">
                    {moment(manager.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                </Col> */}
                <Col lg="4">
                  <h4>Email</h4>
                  <p className="text-muted">{manager?.email}</p>
                </Col>
                <Col lg="4">
                  <h4>Phone</h4>
                  <p className="text-muted">{manager?.phone}</p>
                </Col>
                {manager?.calendly_link && (
                  <Col lg="4">
                    <h4>Calendly Link</h4>
                    <p className="text-muted">{manager?.calendly_link}</p>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default UserDetails;

import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Badge,
  Spinner,
} from "reactstrap";
import Pagination from "../../Components/Common/Pagination";
import UploadLeadModal from "../../Components/leads/UploadLead";
import AddLeadModal from "../../Components/leads/AddLeadModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";
import EditLeadModal from "../../Components/leads/EditLeadModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  deleteLead,
  getAllLeads,
  getLead,
  reset,
} from "../../redux/leads/lead.slices";
import { toast } from "react-toastify";

function Leads() {
  const dispatch = useDispatch();

  const [showUpload, setShowUpload] = useState(false);
  const [showAddNewLead, setShowAddNewLead] = useState(false);
  const [showEditLead, setShowEditLead] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [leadData, setleadData] = useState(null);

  const [filteredLeads, setFilteredLeads] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);

  const { isLoading, leads, message, lead, isSuccess } = useSelector(
    (state) => state.leads
  );

  useEffect(() => {
    dispatch(getAllLeads());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Lead " + message);

      setShowAddNewLead(false);
      setshowDelete(false);
      setShowEditLead(false);
      dispatch(reset());
      dispatch(getAllLeads());
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    const re = new RegExp(keyword, "i");

    setFilteredLeads(
      leads?.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.match(re)
        )
      )
    );

    //get unique data
  }, [keyword, leads]);

  const handleDelete = () => {
    dispatch(deleteLead(leadData._id));
  };

  const columns = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "Address",
      label: "Address",
    },
    {
      name: "dob",
      label: "dob",
    },
    {
      name: "Ownership",
      label: "Ownership",
    },
    {
      name: "Grade",
      label: "Grade",
    },
    {
      name: "package",
      label: "package",
    },
    {
      name: "status",
      label: "Status",
    },
    // {
    //   name: "createdAt",
    //   label: "Created Date",
    // },
  ];

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredLeads.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filteredLeads.length / recordsPerPage);

  function stringTruncator(text, count) {
    return text?.slice(0, count) + (text?.length > count ? "..." : "");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Leads" pageTitle="Leads" />

          <Card className="mt-3">
            <CardHeader>
              <div className="d-lg-flex justify-content-between w-100">
                <div className="d-flex gap-3">
                  <SearchInput
                    autoComplete="on"
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                </div>
                <div className="d-flex mb-3 gap-3">
                  <Button
                    color="warning"
                    onClick={() => setShowUpload(!showUpload)}
                  >
                    Upload Csv
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setShowAddNewLead(!showAddNewLead)}
                  >
                    Add New Lead
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {/* <table className="table table-striped table-responsive table-hover"> */}
              <table className="table table-striped table-lg table-responsive-lg table-hover">
                <thead>
                  <tr>
                    {columns.map((d, i) => (
                      <th
                        key={i}
                        className={d.label === "Status" && `text-center`}
                      >
                        {d.label}
                      </th>
                    ))}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr className="text-center">
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : currentRecords.length <= 0 ? (
                    <tr>
                      <td colSpan={9} className="text-center fw-bolder py-5">
                        No Data found
                      </td>
                    </tr>
                  ) : (
                    currentRecords.map((lead, i) => (
                      <tr key={i}>
                        <td>
                          {stringTruncator(
                            `${lead?.firstname} ${lead?.lastname}`,
                            20
                          )}
                        </td>
                        <td>
                          {stringTruncator(
                            `${lead?.street}, ${lead?.city}, ${lead?.state}, ${lead?.zipcode}`,
                            20
                          )}
                        </td>
                        {/* <td>{lead?.email}</td> */}
                        <td className="text-nowrap">
                          {moment(lead?.dob).format("MM/DD/YYYY")}
                        </td>

                        <td className="text-center">{lead?.home_ownership}</td>
                        <td className="text-center">
                          {lead?.credit_grade ? lead?.credit_grade : "-"}
                        </td>
                        <td>{lead?.package?.name}</td>
                        <td className="text-center">
                          <Badge> {stringTruncator(lead?.status, 20)}</Badge>
                        </td>
                        {/* <td>
                          {stringTruncator(
                            moment(lead?.createdAt).format(
                              "MMM Do YYYY, h:mm:ss a"
                            ),
                            20
                          )}
                        </td> */}

                        <td className="text-center">
                          <div className="dropdown d-md-flex profile-1">
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="dropdown"
                              className="nav-link mx-auto leading-none d-flex px-1"
                            >
                              <i className="ri-more-2-line"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                              <Link
                                to={`/leads/${lead?._id}`}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-eye-line"></i>{" "}
                                View Details
                              </Link>
                              <button
                                onClick={() => {
                                  setShowEditLead(!showEditLead);
                                  // setleadData(lead);
                                  dispatch(getLead(lead?._id));
                                }}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-edit-line"></i>{" "}
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  setshowDelete(!showDelete);
                                  setleadData(lead);
                                }}
                                className="btn dropdown-item"
                              >
                                <i className="dropdown-icon ri-delete-bin-6-line"></i>{" "}
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </tfoot>
              </table>
            </CardBody>
          </Card>
        </Container>
      </div>
      <UploadLeadModal
        show={showUpload}
        onCloseClick={() => setShowUpload(false)}
      />
      <AddLeadModal
        show={showAddNewLead}
        onCloseClick={() => setShowAddNewLead(!showAddNewLead)}
      />
      {!isLoading && lead && (
        <EditLeadModal
          show={showEditLead}
          lead={lead}
          onCloseClick={() => setShowEditLead(!showEditLead)}
        />
      )}
      <DeleteModal
        show={showDelete}
        onCloseClick={() => setshowDelete(!showDelete)}
        onDeleteClick={handleDelete}
      />
    </React.Fragment>
  );
}

export default Leads;

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPackage } from "../../redux/packages/package.slices";

export default function PackageDetails() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { isLoading, packageData } = useSelector((state) => state.packages);

  useEffect(() => {
    dispatch(getPackage(id));
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Package's Detail" pageTitle="Packages" />
        <div className="mb-3 d-flex justify-content-between">
          <Link to={"/packages"}>Go back</Link>
          {/* <button onClick={() => {}} className="btn btn-info">
            <i className="ri-pencil-text-line"></i>
          </button> */}
        </div>
        {isLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Card>
            <CardHeader className="bg-soft-dark">
              <h2 className="my-3 text-primary">
                {packageData?.name}'s <span>details</span>{" "}
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                {/* <Col lg="4">
                  <h4>ID</h4>
                  <p className="text-muted">{packageData?._id}</p>
                </Col> */}
                <Col lg="4">
                  <h4>Stripe ID</h4>
                  <p className="text-muted">{packageData?.price_id}</p>
                </Col>
                <Col lg="4">
                  <h4>Amount</h4>
                  <p className="text-muted">${packageData?.amount}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
}

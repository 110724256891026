import React from "react";
import { Link, NavLink } from "react-router-dom";
import _navManager from "../routes/_managerLinks";
import _nav from "../routes/_naLinks";
import { useDispatch, useSelector } from "react-redux";

// export const user = JSON.parse(localStorage.getItem("user"));

const TheSideBar = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="sticky">
      <div className="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
      <aside className="app-sidebar bg-dark-gradient text-white overflow-scroll">
        <div className="side-header bg-dark-gradient">
          <Link className="header-brand1" to={"/"}>
            <img
              // src="/assets/images/brands/logos/white.png"
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              src="./assets/images/brands/logos/mobile.png"
              className="header-brand-img toggle-logo"
              alt="logo"
            />
            <img
              // src="/assets/images/brands/logos/white.png"
              className="header-brand-img light-logo"
              height={20}
              alt="logo"
            />
            <img
              src="/assets/images/brands/logos/white.png"
              className="header-brand-img light-logo1"
              alt="logo"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div className="main-sidemenu">
          <div className="slide-left disabled" id="slide-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
            </svg>
          </div>
          {!user?.isAdmin ? (
            <ul className="side-menu">
              {_navManager.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.isHeader ? (
                    <li className="sub-category">
                      <h3>{item.label}</h3>
                    </li>
                  ) : (
                    <React.Fragment>
                      <NavLink
                        to={`${item.link}`}
                        className={(data) =>
                          data.isActive
                            ? "bg-gray text-white rounded-end rounded-pill  side-menu__item"
                            : "bg-transparent side-menu__item text-white"
                        }
                      >
                        <i className={`${item.icon} text-white me-4`}></i>
                        <span className="side-menu__label"> {item.label}</span>
                        {item._children && (
                          <i className="side-menu__sub-arrow fa fa-angle-right"></i>
                        )}
                      </NavLink>
                      <li className="slide">
                        <div
                          className="collapse border-start collapse-horizontal ms-5 collapseExample"
                          // id="collapseExample"
                        >
                          {item._children &&
                            item._children.map((c, index) => (
                              <NavLink
                                key={index}
                                to={`${c.to}`}
                                className={(data) =>
                                  data.isActive ? "bg-danger" : "bg-transparent"
                                }
                              >
                                <p className="side-menu__item">{c.name}</p>
                              </NavLink>
                            ))}
                        </div>
                      </li>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </ul>
          ) : (
            <ul className="side-menu">
              {_nav.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.isHeader ? (
                    <li className="sub-category">
                      <h3>{item.label}</h3>
                    </li>
                  ) : (
                    <React.Fragment>
                      <NavLink
                        to={`${item.link}`}
                        className={(data) =>
                          data.isActive
                            ? "bg-gray text-white rounded-end rounded-pill  side-menu__item"
                            : "bg-transparent side-menu__item text-white"
                        }
                      >
                        <i className={`${item.icon} text-white me-4`}></i>
                        <span className="side-menu__label"> {item.label}</span>
                        {item._children && (
                          <i className="side-menu__sub-arrow fa fa-angle-right"></i>
                        )}
                      </NavLink>
                      <li className="slide">
                        <div
                          className="collapse border-start collapse-horizontal ms-5 collapseExample"
                          // id="collapseExample"
                        >
                          {item._children &&
                            item._children.map((c, index) => (
                              <NavLink
                                key={index}
                                to={`${c.to}`}
                                className={(data) =>
                                  data.isActive ? "bg-danger" : "bg-transparent"
                                }
                              >
                                <p className="side-menu__item">{c.name}</p>
                              </NavLink>
                            ))}
                        </div>
                      </li>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </ul>
          )}

          <div className="slide-right" id="slide-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
            </svg>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default TheSideBar;
